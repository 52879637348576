dmx.Component('masonry', {

    extends: 'repeat',

    attributes: {
        columns: {
            // the number of columns to create
            type: Number,
            default: 4
        },

        'columns-sm': { // >= 480px
            type: Number,
            default: null
        },

        'columns-md': { // >= 768px
            type: Number,
            default: null
        },

        'columns-lg': { // >= 992px
            type: Number,
            default: null
        },

        'columns-xl': { // >= 1200px
            type: Number,
            default: null
        },

        'columns-xxl': { // >= 1400px
            type: Number,
            default: null
        },

        gutter: {
            // the gutter size in px
            type: Number,
            default: 15
        },

        'gutter-sm': { // >= 480px
            type: Number,
            default: null
        },

        'gutter-md': { // >= 768px
            type: Number,
            default: null
        },

        'gutter-lg': { // >= 992px
            type: Number,
            default: null
        },

        'gutter-xl': { // >= 1200px
            type: Number,
            default: null
        },

        'gutter-xxl': { // >= 1400px
            type: Number,
            default: null
        },

        'preserve-order': {
            // will order the items from left to right into the columns
            // when false it will optimize layout by equalizing the height of each column
            type: Boolean,
            default: false
        },

        animated: {
            type: Boolean,
            default: false
        },

        'animation-duration': {
            type: Number,
            default: 400
        }
    },

    methods: {
        reflow: function() {
            // allow manual reflow (when user changes size of item outside of app connect)
            this.reflow();
        }
    },

    render: function(node) {
        this.reflow = dmx.debounce(this.reflow.bind(this));
        this.breakpoints = { sm: 480, md: 768, lg: 992, xl: 1200, xxl: 1400 };
        this.resizeObserver = new ResizeObserver(this.reflow);
        this.resizeObserver.observe(node);
        
        // container must have position relative
        node.style.setProperty('position', 'relative');

        dmx.Component('repeat').prototype.render.call(this, node);        
    },

    _update: function() {
        dmx.Component('repeat').prototype._update.call(this);
        this.reflow();
    },

    reflow: function() {
        if (!this.children.length || this.insideReflow) return;

        this.$node.querySelectorAll('img').forEach(img => {
            if (!img.dmxMasonry) {
                img.addEventListener('load', this.reflow, { once: true });
                if (img.src) img.src = img.src;
                img.dmxMasonry = true;
            }
        });

        let viewportWidth = window.innerWidth;
        let { columns, gutter } = this.props;

        ['sm','md','lg','xl','xxl'].forEach(breakpoint => {
            if (viewportWidth >= this.breakpoints[breakpoint]) {
                columns = this.props['columns-' + breakpoint] || columns;
                gutter = this.props['gutter-' + breakpoint] || gutter;
            }
        });

        let nodes = Array.from(this.$node.childNodes).filter(node => node.nodeType == 1);
        let style = window.getComputedStyle(this.$node);
        let paddingLeft = parseInt(style.paddingLeft) || 0;
        let paddingRight = parseInt(style.paddingRight) || 0;
        let columnWidth = Math.floor((this.$node.clientWidth - paddingLeft - paddingRight - ((columns - 1) * gutter)) / columns);

        for (let node of nodes) {
            node.style.setProperty('box-sizing', 'border-box');
            node.style.setProperty('width', columnWidth + 'px');
        }

        // dispatch resize event for components that still listen to that for updating
        window.dispatchEvent(new Event('resize'));
        
        let columnHeights = Array(columns).fill(0);
        let nodesHeights = nodes.map(node => node.clientHeight);

        nodes.forEach((node, index) => {
            let i = this.props['preserver-order'] ? index % columns : columnHeights.indexOf(Math.min.apply(Math, columnHeights));
            let x = (i * columnWidth) + (i * gutter);
            let y = columnHeights[i];

            node.style.setProperty('transform', `translate3d(${x}px, ${y}px, 0px)`);

            if (nodesHeights[index]) {
                if (!node.dmxMasonryInit) {
                    node.style.setProperty('position', 'absolute');

                    if (this.props.animated) {
                        node.style.setProperty('transition', 'transform ' + this.props['animation-duration'] + 'ms');
                    }

                    requestAnimationFrame(() => {
                        node.style.setProperty('visibility', 'visible');
                    });

                    node.dmxMasonryInit = true;
                }

                columnHeights[i] += nodesHeights[index] + gutter;
            }
        });

        this.$node.style.setProperty('height', (Math.max.apply(Math, columnHeights) - gutter) + 'px');
    }

});
